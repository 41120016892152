import React from 'react';
import { LineChart, StatCard } from '../../../../common/components/charts';
import { 
  HiOutlineChartBar as HiOutlineTemperature,  // アイコンを変更
  HiOutlineCloud 
} from 'react-icons/hi';

const ChowaMainDashboard = () => {
  // 仮のデータ
  const temperatureData = {
    labels: ['9:00', '10:00', '11:00', '12:00', '13:00', '14:00'],
    datasets: [{
      label: '温度',
      data: [22, 23, 24, 24.5, 23.8, 23.2],
      borderColor: 'rgb(75, 192, 192)',
    }]
  };

  const stats = [
    {
      title: '現在の温度',
      value: '23.2°C',
      icon: HiOutlineTemperature,
      trend: 'up',
      trendValue: '+1.2°C'
    },
    {
      title: '現在の湿度',
      value: '45%',
      icon: HiOutlineCloud,
      trend: 'down',
      trendValue: '-2%'
    }
  ];

  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
        Chowa Cloud Monitoring System
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {stats.map((stat, index) => (
          <StatCard key={index} {...stat} />
        ))}
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
        <LineChart
          data={temperatureData}
          title="温度トレンド"
        />
      </div>
    </div>
  );
};

export default ChowaMainDashboard; 