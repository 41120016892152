import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TopPageBar from './TopPageBar';
import { FaBars } from 'react-icons/fa';

const WaveAnimation = ({ height }) => {
  const canvasRef = React.useRef(null);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = height;
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    const drawWave = (t) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();

      const waveColors = ['rgba(255, 255, 255, 0.3)', 'rgba(255, 255, 255, 0.2)', 'rgba(255, 255, 255, 0.1)'];

      waveColors.forEach((color, index) => {
        ctx.strokeStyle = color;
        ctx.lineWidth = 2;
        ctx.moveTo(0, canvas.height / 2);

        for (let i = 0; i < canvas.width; i++) {
          ctx.lineTo(
            i,
            canvas.height / 2 +
              Math.sin(i * 0.02 + t + index * 2) * 20 +
              Math.sin(i * 0.01 + t + index * 2) * 15
          );
        }

        ctx.stroke();
      });

      animationFrameId = requestAnimationFrame(() => drawWave(t + 0.05));
    };

    drawWave(0);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, [height]);

  return <canvas ref={canvasRef} style={{ width: '100%', height: `${height}px`, position: 'absolute', top: 0, left: 0, zIndex: 0 }} />;
};

const TopPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const navigate = useNavigate();

  const menuItems = [
    { 
      id: 1, 
      title: 'お客様ログイン', 
      path: '/signin', 
      icon: '🔐',
      isMain: true,
      description: 'クラウドサービスへログイン'
    },
    { id: 2, title: 'ソフトウェアDL', path: '/Download', icon: '💾' },
    { 
      id: 3, 
      title: '計測ソフト本社', 
      path: 'https://keisoku-soft.co.jp/', 
      icon: '🏢', 
      external: true 
    },
    { 
      id: 4, 
      title: 'お問い合わせ', 
      path: 'https://keisoku-soft.co.jp/contact/',
      icon: '✉️',
      external: true
    },
    { id: 5, title: '準備中', path: '#', icon: '🔧' },
  ];

  const handleNavigation = (item) => {
    if (item.external) {
      window.open(item.path, '_blank', 'noopener,noreferrer');
    } else {
      navigate(item.path);
    }
  };

  const handleContactClick = () => {
    window.open('https://keisoku-soft.co.jp/contact/', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-600 via-blue-500 to-blue-400 text-white relative">
      {/* 波のアニメーション */}
      <WaveAnimation height={300} />

      {/* サイドバーオーバーレイ */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden"
          onClick={toggleSidebar}
        />
      )}

      {/* サイドバー */}
      <div
        className={`
          fixed inset-y-0 left-0 z-30 w-64
          transform transition-transform duration-300 ease-in-out
          lg:transform-none lg:translate-x-0
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
        `}
      >
        <TopPageBar onClose={() => setIsSidebarOpen(false)} />
      </div>

      {/* ハンバーガーメニューボタン - サイドバーが開いているときは非表示 */}
      <button
        onClick={toggleSidebar}
        className={`
          fixed z-50 top-4 left-4 p-2 bg-blue-600 text-white rounded-full shadow-lg 
          lg:hidden focus:outline-none focus:ring-2 focus:ring-blue-500
          transition-opacity duration-300
          ${isSidebarOpen ? 'opacity-0 pointer-events-none' : 'opacity-100'}
        `}
        aria-label="Toggle Menu"
      >
        <FaBars size={24} />
      </button>

      {/* メインコンテンツ */}
      <div className="lg:ml-64 transition-all duration-300 relative z-10">
        <div className="max-w-6xl mx-auto px-4 py-8">
          {/* Header */}
          <header className="text-center mb-16 pt-16">
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">KSK Air Cloud</h1>
          </header>

          {/* Main Content */}
          <main>
            {/* メインログインボタン */}
            {menuItems.filter(item => item.isMain).map((item) => (
              <div
                key={item.id}
                className="mb-12 bg-white bg-opacity-20 rounded-lg p-8 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 cursor-pointer text-center mx-auto max-w-2xl"
                onClick={() => handleNavigation(item)}
              >
                <div className="text-5xl mb-4">{item.icon}</div>
                <h2 className="text-2xl font-bold">{item.title}</h2>
              </div>
            ))}

            {/* その他のメニューアイテム */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-8 max-w-4xl mx-auto">
              {menuItems.filter(item => !item.isMain).map((item) => (
                <div
                  key={item.id}
                  className="bg-white bg-opacity-20 rounded-lg p-4 md:p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 cursor-pointer text-center"
                  onClick={() => handleNavigation(item)}
                >
                  <div className="text-3xl mb-3">{item.icon}</div>
                  <h2 className="text-lg font-semibold">{item.title}</h2>
                </div>
              ))}
            </div>
          </main>
        </div>

        {/* Footer */}
        <footer className="bg-blue-800/50 backdrop-blur-md text-white py-12 mt-24 border-t border-white/10">
          <div className="max-w-6xl mx-auto px-4">
            <div className="flex flex-wrap justify-between">
              <div className="w-full md:w-1/2 mb-6 md:mb-0">
                <h3 className="text-2xl md:text-3xl font-bold mb-4">KSK Air Cloudとは?</h3>
                <p className="text-base md:text-lg">・カスタマイズ可能なDashboardの提供</p>
                <p className="text-base md:text-lg">・データ保存サービス</p>
                <p className="text-base md:text-lg">・データ分析サービス</p>
                <p className="text-base md:text-lg">・データベースバックアップ提供可能</p>
              </div>
              <div className="w-full md:w-1/2">
                <h4 className="text-xl md:text-2xl font-semibold mb-4">お問い合わせ</h4>
                <p className="text-base md:text-lg mb-4">下記リンクより本社お問い合わせに移ります</p>
                <button 
                  onClick={handleContactClick}
                  className="bg-white text-blue-800 font-bold py-3 px-8 rounded-xl text-lg hover:bg-blue-50 transition duration-300 shadow-lg"
                >
                  お問い合わせ
                </button>
              </div>
            </div>
            <div className="mt-8 md:mt-12 text-center">
              <p className="text-base md:text-lg">&copy; 2024 make by Yashima.</p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default TopPage;