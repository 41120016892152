import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaCog, FaDownload, FaHeadset, FaEnvelope, FaBars, FaTimes } from 'react-icons/fa';

const TopPageBar = ({ onClose }) => {
  const location = useLocation();

  const menuItems = [
    { title: 'ホーム', path: '/', icon: FaHome },
    { title: '新機能テスト', path: '/mqtt', icon: FaCog },
    { title: 'ダウンロード', path: '/download', icon: FaDownload },
    { 
      title: 'お問い合わせ', 
      path: 'https://keisoku-soft.co.jp/contact/', 
      icon: FaEnvelope,
      external: true 
    },
  ];

  return (
    <nav className="h-full bg-gray-900 text-white overflow-y-auto">
      <div className="p-6">
        <div className="flex items-center justify-between mb-8">
          <h2 className="text-2xl font-bold text-blue-400">KSK Air Cloud</h2>
          <button
            onClick={onClose}
            className="lg:hidden p-2 rounded-lg hover:bg-gray-800"
          >
            <FaTimes size={24} />
          </button>
        </div>
        <ul>
          {menuItems.map((item, index) => {
            const Icon = item.icon;
            return (
              <li key={index} className="mb-4">
                {item.external ? (
                  <a
                    href={item.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center text-lg p-3 rounded-lg transition-all duration-300 text-gray-300 hover:bg-gray-800 hover:text-white"
                  >
                    <Icon className="mr-4" size={20} />
                    <span>{item.title}</span>
                  </a>
                ) : (
                  <Link
                    to={item.path}
                    className={`flex items-center text-lg p-3 rounded-lg transition-all duration-300 ${
                      location.pathname === item.path
                        ? 'bg-blue-600 text-white'
                        : 'text-gray-300 hover:bg-gray-800 hover:text-white'
                    }`}
                  >
                    <Icon className="mr-4" size={20} />
                    <span>{item.title}</span>
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default TopPageBar;