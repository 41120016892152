import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '../../theme/themeContext';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';

const DashboardLayout = ({ routes, sidebarConfig }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <ThemeProvider>
      <div className="h-screen flex bg-gray-100 dark:bg-gray-900 overflow-hidden">
        {/* サイドバーオーバーレイ */}
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden"
            onClick={toggleSidebar}
          />
        )}

        {/* サイドバー */}
        <div
          className={`
            fixed inset-y-0 left-0 z-30 w-64
            transform transition-transform duration-300 ease-in-out
            lg:transform-none lg:translate-x-0
            ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
          `}
        >
          <Sidebar sidebarConfig={sidebarConfig} />
        </div>

        {/* メインコンテンツ */}
        <div className="flex-1 flex flex-col lg:ml-64">
          <Header toggleSidebar={toggleSidebar} />
          <main className="flex-1 overflow-y-auto pt-4 pb-6">
            <div className="px-4 h-full">
              <Routes>
                {/* ルートパスへのアクセス時に/mainへリダイレクト */}
                <Route 
                  path="/" 
                  element={<Navigate to="main" replace />} 
                />
                
                {/* 通常のルート */}
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}

                {/* 未定義のパスへのアクセス時も/mainへリダイレクト */}
                <Route 
                  path="*" 
                  element={<Navigate to="main" replace />} 
                />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default DashboardLayout; 