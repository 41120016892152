import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ja from 'date-fns/locale/ja';
import {
  Box,
  Paper,
  Typography,
  Checkbox,
  Button,
  Alert,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@mui/material';
import {
  HiOutlineDownload,
  HiOutlineDocumentText
} from 'react-icons/hi';
import { useTheme } from '../../../../common/theme/themeContext';
import axios from 'axios';
import JSZip from 'jszip';

const DataDownload = () => {
  const { isDark } = useTheme();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableFiles, setAvailableFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAvailableFiles = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get('/api/ns/ns6989/available-files/', {
          params: {
            year: selectedDate.getFullYear(),
            month: selectedDate.getMonth() + 1
          }
        });
        setAvailableFiles(response.data);
        setSelectedFiles([]); // 月が変わったら選択をリセット
      } catch (error) {
        setError('データの取得に失敗しました');
        console.error('Error fetching files:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableFiles();
  }, [selectedDate]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedFiles(availableFiles.map(file => file.id));
    } else {
      setSelectedFiles([]);
    }
  };

  const handleSelectFile = (fileId) => {
    setSelectedFiles(prev => {
      if (prev.includes(fileId)) {
        return prev.filter(id => id !== fileId);
      } else {
        return [...prev, fileId];
      }
    });
  };

  const handleDownload = async () => {
    if (selectedFiles.length === 0) return;

    setLoading(true);
    try {
      if (selectedFiles.length === 1) {
        // 単一ファイルの場合は直接ダウンロード
        const response = await axios.get(`/api/ns/ns6989/download/${selectedFiles[0]}`, {
          responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `data_${selectedFiles[0]}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        // 複数ファイルの場合はZIPにまとめる
        const zip = new JSZip();
        const downloads = selectedFiles.map(async fileId => {
          const response = await axios.get(`/api/ns/ns6989/download/${fileId}`, {
            responseType: 'blob'
          });
          zip.file(`data_${fileId}.csv`, response.data);
        });

        await Promise.all(downloads);
        const content = await zip.generateAsync({ type: 'blob' });
        const url = window.URL.createObjectURL(content);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `data_${selectedDate.getFullYear()}_${selectedDate.getMonth() + 1}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      setError('ダウンロードに失敗しました');
      console.error('Download failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <Box sx={{ p: { xs: 2, sm: 3 } }}>
        <Typography variant="h4" gutterBottom sx={{ color: isDark ? 'white' : 'text.primary' }}>
          データダウンロード
        </Typography>

        <Paper elevation={3} sx={{ 
          p: { xs: 2, sm: 3 },
          bgcolor: isDark ? '#1f2937' : 'background.paper',
          color: isDark ? 'white' : 'text.primary'
        }}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              月を選択
            </Typography>
            <DatePicker
              views={['year', 'month']}
              label="年月"
              value={selectedDate}
              onChange={setSelectedDate}
              format="yyyy年MM月"
              slotProps={{ textField: { 
                size: 'small',
                sx: {
                  '& .MuiInputLabel-root': {
                    color: isDark ? 'grey.400' : 'inherit'
                  },
                  '& .MuiOutlinedInput-root': {
                    color: isDark ? 'white' : 'inherit',
                    '& fieldset': {
                      borderColor: isDark ? 'grey.700' : 'inherit'
                    }
                  }
                }
              }}}
            />
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : availableFiles.length > 0 ? (
            <>
              <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFiles.length === availableFiles.length}
                      indeterminate={selectedFiles.length > 0 && selectedFiles.length < availableFiles.length}
                      onChange={handleSelectAll}
                    />
                  }
                  label="すべて選択"
                />
                <Button
                  variant="contained"
                  startIcon={<HiOutlineDownload />}
                  onClick={handleDownload}
                  disabled={selectedFiles.length === 0}
                >
                  {selectedFiles.length > 1 ? 'まとめてダウンロード' : 'ダウンロード'}
                </Button>
              </Box>

              <Divider sx={{ my: 2 }} />

              <List>
                {availableFiles.map((file) => (
                  <ListItem
                    key={file.id}
                    sx={{
                      '&:hover': {
                        bgcolor: isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)'
                      }
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={selectedFiles.includes(file.id)}
                        onChange={() => handleSelectFile(file.id)}
                      />
                    </ListItemIcon>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <HiOutlineDocumentText size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary={file.name}
                      secondary={file.date}
                      secondaryTypographyProps={{
                        sx: { color: isDark ? '#9ca3af' : 'inherit' }
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            <Alert severity="info">
              選択された月のデータはありません
            </Alert>
          )}
        </Paper>
      </Box>
    </LocalizationProvider>
  );
};

export default DataDownload; 