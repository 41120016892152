import React from 'react';
import DashboardLayout from '../../../common/components/Layout/DashboardLayout';
import { sidebarConfig } from './config/sidebarConfig';
import Ns6989MainDashboard from './dashboard/main/Ns6989MainDashboard';
import DataDownload from './components/DataDownload';

const routes = [
  {
    path: 'main',
    component: Ns6989MainDashboard
  },
  {
    path: 'download',
    component: DataDownload
  }
];

const Ns6989App = () => {
  return (
    <DashboardLayout 
      routes={routes} 
      sidebarConfig={sidebarConfig}
    />
  );
};

export default Ns6989App;