import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [tokens, setTokens] = useState(() => {
    const savedTokens = localStorage.getItem('tokens');
    return savedTokens ? JSON.parse(savedTokens) : null;
  });

  const login = async (accessToken, refreshToken, authType) => {
    const newTokens = { access: accessToken, refresh: refreshToken };
    setTokens(newTokens);
    localStorage.setItem('tokens', JSON.stringify(newTokens));
    localStorage.setItem('authType', authType);
  };

  const logout = () => {
    setTokens(null);
    localStorage.removeItem('tokens');
    localStorage.removeItem('authType');
  };

  const getAuthType = () => {
    return localStorage.getItem('authType') || 'chowa';
  };

  const value = {
    tokens,
    login,
    logout,
    getAuthType,
    isAuthenticated: !!tokens,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
