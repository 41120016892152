import TopPage from './TopPage/TopPage';
import SignIn from './common/SignIn/SignIn';
import Download from './Download/Download';
import ChowaApp from './clients/chowa/ChowaApp';
import PrivateRoute from './common/components/PrivateRoute';
import Ns6989App from './clients/ns/ns6989/Ns6989App';

const routes = [
  {
    path: '/',
    element: <TopPage />,
    title: 'ホーム'
  },
  {
    path: '/chowa/*',
    element: (
      <PrivateRoute authType="chowa">
        <ChowaApp />
      </PrivateRoute>
    ),
    title: 'Chowa Dashboard'
  },
  {
    path: '/download',
    element: <Download />,
    title: 'ダウンロード'
  },
  {
    path: '/signin',
    element: <SignIn />,
    title: 'サインイン'
  },
  {
    path: '/ns6989/*',
    element: (
      <PrivateRoute authType="ns6989">
        <Ns6989App />
      </PrivateRoute>
    ),
    title: 'NS6989 Dashboard'
  }
];

export default routes;