import { HiOutlineHome, HiOutlineDownload } from 'react-icons/hi';

export const sidebarConfig = {
  title: 'NS6989',
  menuItems: [
    {
      path: 'main',
      name: 'dashboard',
      icon: HiOutlineHome
    },
    {
      path: 'download',
      name: 'download',
      icon: HiOutlineDownload
    }
  ]
};