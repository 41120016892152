import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';

export default function SignIn() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    
    try {
      const response = await axios.post('https://ksk-aircloud.com/api/token/', {
        username: data.get('id'),
        password: data.get('password'),
      });
      
      const username = data.get('id');
      let authSystem = 'chowa';
      
      if (username.toUpperCase().startsWith('NS')) {
        authSystem = 'ns6989';
      }
      
      await login(
        response.data.access, 
        response.data.refresh,
        authSystem
      );

      if (authSystem === 'ns6989') {
        navigate('/ns6989/main');
      } else {
        navigate('/chowa/main');
      }
      
    } catch (error) {
      console.error('Login error:', error);
      setError('ログインに失敗しました。IDまたはパスワードが正しくありません。');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-blue-900 to-slate-900 flex items-center justify-center p-4">
      <div className="w-full max-w-md">
        <div className="relative bg-slate-800/80 rounded-2xl p-8 shadow-2xl border border-white/10">
          {/* デザイン要素：上部の装飾的なライン */}
          <div className="absolute top-0 left-1/2 -translate-x-1/2 w-32 h-1 bg-gradient-to-r from-blue-400 to-blue-600 rounded-full" />
          
          {/* ロゴ部分 */}
          <div className="text-center mb-8">
            <div className="inline-block p-3 rounded-full bg-gradient-to-r from-blue-500 to-blue-600 mb-4 shadow-lg">
              <svg className="w-8 h-8 text-white transform transition-transform duration-300 ease-in-out hover:rotate-180" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
              </svg>
            </div>
            <h2 className="text-3xl font-bold text-white mb-2">KSK Air Cloud</h2>
            <p className="text-blue-200 text-sm">データの見える化注力中</p>
          </div>

          {/* フォーム部分 */}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <div className="relative">
                <label className="block text-sm font-medium text-blue-200 mb-2">ID</label>
                <input
                  type="text"
                  name="id"
                  className="w-full px-4 py-3 bg-white/5 border border-blue-300/20 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 text-white placeholder-blue-300/50"
                  placeholder="ユーザーIDを入力"
                  required
                />
                <div className="absolute right-3 top-9 text-blue-300/50">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                </div>
              </div>

              <div className="relative">
                <label className="block text-sm font-medium text-blue-200 mb-2">パスワード</label>
                <input
                  type="password"
                  name="password"
                  className="w-full px-4 py-3 bg-white/5 border border-blue-300/20 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 text-white placeholder-blue-300/50"
                  placeholder="パスワードを入力"
                  required
                />
                <div className="absolute right-3 top-9 text-blue-300/50">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                  </svg>
                </div>
              </div>
            </div>

            {error && (
              <div className="text-red-400 text-sm bg-red-500/10 p-3 rounded-lg border border-red-500/20">
                {error}
              </div>
            )}

            <button
              type="submit"
              disabled={isLoading}
              className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white py-3 rounded-lg font-medium hover:from-blue-600 hover:to-blue-700 transition-all duration-300 transform hover:scale-[1.02] disabled:opacity-50 shadow-lg hover:shadow-blue-500/25"
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  ログイン中...
                </span>
              ) : 'ログイン'}
            </button>
          </form>



          {/* デザイン要素：下部の装飾的なライン */}
          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-32 h-1 bg-gradient-to-r from-blue-600 to-blue-400 rounded-full" />
        </div>
      </div>
    </div>
  );
}