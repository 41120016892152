import React from 'react';
import { Box, Typography, useTheme as useMuiTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja';
import DeviceDataChart from '../../components/DeviceDataChart';
import { useTheme } from '../../../../../common/theme/themeContext';

const Ns6989MainDashboard = () => {
  const { isDark } = useTheme();
  const muiTheme = useMuiTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <Box sx={{ 
        p: 3,
        bgcolor: isDark ? '#1f2937' : 'background.paper'  // dark:bg-gray-800に相当
      }}>
        <Typography 
          variant="h4" 
          gutterBottom
          sx={{ 
            color: isDark ? 'white' : 'text.primary'
          }}
        >
          相之川 NSSF高断熱住宅 消費電力
        </Typography>
        <DeviceDataChart />
      </Box>
    </LocalizationProvider>
  );
};

export default Ns6989MainDashboard;