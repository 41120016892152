import React, { useState } from 'react';
import { 
  HiOutlineUser, 
  HiOutlineCog, 
  HiOutlineLogout 
} from 'react-icons/hi';

const UserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    { 
      icon: HiOutlineUser, 
      label: 'プロフィール', 
      action: () => console.log('profile') 
    },
    { 
      icon: HiOutlineCog, 
      label: '設定', 
      action: () => console.log('settings') 
    },
    { 
      icon: HiOutlineLogout, 
      label: 'ログアウト', 
      action: () => console.log('logout') 
    }
  ];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
      >
        <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center">
          <HiOutlineUser className="w-5 h-5 text-gray-600" />
        </div>
        <span className="text-gray-800 dark:text-white">ユーザー名</span>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-lg shadow-lg py-1 bg-white dark:bg-gray-800">
          {menuItems.map((item, index) => (
            <button
              key={index}
              onClick={() => {
                item.action();
                setIsOpen(false);
              }}
              className="w-full flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <item.icon className="w-5 h-5 mr-3" />
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserMenu; 