import React from 'react';
import classNames from 'classnames';

const StatCard = ({ title, value, icon: Icon, trend, trendValue }) => {
  return (
    <div className="p-4 lg:p-6 rounded-lg shadow-lg bg-white dark:bg-gray-800">
      <div className="flex justify-between items-start">
        <div>
          <p className="text-xs lg:text-sm text-gray-600 dark:text-gray-300">{title}</p>
          <h3 className="text-lg lg:text-2xl font-bold mt-1 lg:mt-2 text-gray-800 dark:text-white">
            {value}
          </h3>
        </div>
        <div className="p-2 lg:p-3 rounded-full bg-blue-600">
          <Icon className="w-4 h-4 lg:w-6 lg:h-6 text-white" />
        </div>
      </div>
      
      <div className="mt-3 lg:mt-4 flex items-center">
        <span className={classNames(
          'text-xs lg:text-sm',
          trend === 'up' ? 'text-green-500' : 'text-red-500'
        )}>
          {trendValue}
        </span>
        <span className="text-xs lg:text-sm ml-2 text-gray-600 dark:text-gray-300">
          先月比
        </span>
      </div>
    </div>
  );
};

export default StatCard; 