import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const PrivateRoute = ({ children, authType }) => {
  const { isAuthenticated, getAuthType } = useAuth();
  const location = useLocation();
  const currentAuthType = getAuthType();

  if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (authType && authType !== currentAuthType) {
    let redirectPath;
    switch (currentAuthType) {
      case 'ns6989':
        redirectPath = '/ns6989/main';
        break;
      case 'chowa':
        redirectPath = '/chowa/main';
        break;
      default:
        console.error(`Unexpected user type: ${currentAuthType}`);
        redirectPath = '/error';
        break;
    }
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default PrivateRoute; 