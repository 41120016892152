import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '../../theme/themeContext';
import classNames from 'classnames';

const Sidebar = ({ sidebarConfig = { title: 'Dashboard', menuItems: [] } }) => {
  const location = useLocation();
  const { sidebarColor } = useTheme();

  const bgColors = {
    blue: 'bg-blue-600',
    green: 'bg-green-600',
    red: 'bg-red-600',
    purple: 'bg-purple-600'
  };

  return (
    <div className={`h-full ${bgColors[sidebarColor]} text-white`}>
      {/* ロゴ */}
      <div className="px-6 py-4 border-b border-opacity-20 border-white">
        <h1 className="text-xl font-bold">{sidebarConfig.title}</h1>
      </div>

      {/* ナビゲーション */}
      <nav className="mt-6 px-4">
        {sidebarConfig.menuItems.map((item) => {
          const Icon = item.icon;
          return (
            <Link
              key={item.path}
              to={item.path}
              className={classNames(
                'flex items-center px-4 py-3 rounded-lg transition-colors',
                'hover:bg-white hover:bg-opacity-10',
                location.pathname.includes(item.path) ? 'bg-white bg-opacity-10' : ''
              )}
            >
              <Icon className="w-6 h-6 mr-3" />
              <span>{item.name}</span>
            </Link>
          );
        })}
      </nav>
    </div>
  );
};

export default Sidebar; 