import React from 'react';
import DashboardLayout from '../../common/components/Layout/DashboardLayout';
import { sidebarConfig } from './config/sidebarConfig';
import ChowaMainDashboard from './dashboard/main/ChowaMainDashboard';
import TMS000 from './TMS000/TMS000';

const routes = [
  {
    path: 'main',
    component: ChowaMainDashboard
  },
  {
    path: 'tms000/*',
    component: TMS000
  }
];

const ChowaApp = () => {
  return (
    <DashboardLayout 
      routes={routes} 
      sidebarConfig={sidebarConfig}  // sidebarConfigを明示的に渡す
    />
  );
};

export default ChowaApp; 