import React, { useState, useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { 
  Box, 
  FormGroup, 
  FormControlLabel, 
  Checkbox,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Alert,
  Grid,
  Button
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import axios from 'axios';
import { useTheme } from '../../../../common/theme/themeContext';
import 'chartjs-adapter-date-fns';
import { ja } from 'date-fns/locale';
import zoomPlugin from 'chartjs-plugin-zoom';

// Chart.jsの設定
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
  zoomPlugin
);

const DeviceDataChart = () => {
  const { isDark } = useTheme();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [chartData, setChartData] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState('voltage');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const chartRef = useRef(null);

  // デバイスIDと表示名の対応
  const deviceNames = {
    '24587C9BB96A': '洋室⓶ AC',
    '24587C9BC246': '洋室⓵ AC',
    '24587C9CDF8A': 'LDK AC',
    '3C84279F8EF2': 'レンジフード 換気扇(交換前)',
    '3C84279FAA1E': 'レンジフード 換気扇(交換後)',
    '3C8427A0321A': '冷蔵庫'
  };

  // メトリクスの定義をDBのフィールド名に合わせて更新
  const metrics = {
    voltage: { label: '電圧', unit: 'V' },
    weight: { label: '電力', unit: 'W' },
    electricity: { label: '消費電力', unit: 'kWh' },
    electricCurrent: { label: '電流', unit: 'mA' }
  };

  const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'];

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, selectedMetric]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/ns/ns6989/device-data/`, {
        params: { 
          start_date: format(startDate, 'yyyy-MM-dd'),
          end_date: format(endDate, 'yyyy-MM-dd')
        }
      });
      
      // データをグラフ用に整形
      const datasets = Object.entries(response.data).map(([deviceId, data], index) => ({
        label: deviceNames[deviceId] || deviceId, // デバイス名を表示（対応がない場合はIDを表示）
        data: data.map(item => ({
          x: new Date(item.date),
          y: item[selectedMetric]
        })),
        borderColor: colors[index % colors.length],
        fill: false
      }));

      setChartData({
        datasets
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const resetZoom = () => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: `${metrics[selectedMetric].label}の推移`,
        font: {
          size: 16
        },
        color: isDark ? 'white' : 'black'
      },
      legend: {
        position: 'top',
        labels: {
          color: isDark ? 'white' : 'black'
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      zoom: {
        pan: {
          enabled: false
        },
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          mode: 'x',
          drag: {
            enabled: false
          }
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour',
          displayFormats: {
            hour: 'MM/dd HH:mm'
          }
        },
        title: {
          display: true,
          text: '時間',
          color: isDark ? 'white' : 'black'
        },
        grid: {
          color: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
        },
        ticks: {
          color: isDark ? 'white' : 'black'
        }
      },
      y: {
        title: {
          display: true,
          text: metrics[selectedMetric].unit,
          color: isDark ? 'white' : 'black'
        },
        grid: {
          color: isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
        },
        ticks: {
          color: isDark ? 'white' : 'black'
        }
      }
    },
    interaction: {
      mode: 'index',
      intersect: false,
    }
  };

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        p: { xs: 2, sm: 3 },
        bgcolor: isDark ? '#1f2937' : 'background.paper',
        color: isDark ? 'white' : 'text.primary'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{ color: isDark ? 'white' : 'text.primary' }}
          >
            データ表示設定
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            flexWrap: 'wrap', 
            mb: 2,
            '& .MuiInputLabel-root': {
              color: isDark ? '#9ca3af' : 'inherit'
            },
            '& .MuiOutlinedInput-root': {
              color: isDark ? 'white' : 'inherit',
              '& fieldset': {
                borderColor: isDark ? '#374151' : 'inherit'
              }
            }
          }}>
            <DatePicker
              label="開始日"
              value={startDate}
              onChange={setStartDate}
              format="yyyy/MM/dd"
              slotProps={{ textField: { size: 'small' } }}
            />
            <DatePicker
              label="終了日"
              value={endDate}
              onChange={setEndDate}
              format="yyyy/MM/dd"
              slotProps={{ textField: { size: 'small' } }}
            />
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel>表示項目</InputLabel>
              <Select
                value={selectedMetric}
                onChange={(e) => setSelectedMetric(e.target.value)}
                label="表示項目"
              >
                {Object.entries(metrics).map(([key, { label, unit }]) => (
                  <MenuItem key={key} value={key}>{`${label}[${unit}]`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            mb: 2
          }}>
            <Button
              size="small"
              onClick={resetZoom}
              variant="outlined"
              sx={{
                color: isDark ? 'grey.400' : 'primary.main',
                borderColor: isDark ? 'grey.700' : 'primary.main'
              }}
            >
              ズームをリセット
            </Button>
          </Box>

          <Box sx={{ 
            height: 400, 
            position: 'relative',
            mx: { xs: -1, sm: 0 }
          }}>
            {loading ? (
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                height: '100%'
              }}>
                <CircularProgress />
              </Box>
            ) : (
              chartData && <Line data={chartData} options={chartOptions} ref={chartRef} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DeviceDataChart;