import { 
  HiOutlineHome, 
  HiOutlineChartBar, 
  HiOutlineCloud,
  HiOutlineDatabase  // TMSのアイコン用に追加
} from 'react-icons/hi';

export const sidebarConfig = {
  title: 'Chowa Dashboard',
  menuItems: [
    {
      path: 'main',
      name: 'Dashboard',
      icon: HiOutlineHome
    },
    {
      path: 'tms000',  //TMS00 PATH
      name: 'TMS 000',
      icon: HiOutlineDatabase
    }
  ]
};
