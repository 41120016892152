import React from 'react';
import { HiOutlineSun, HiOutlineMoon, HiOutlineMenu } from 'react-icons/hi';
import { useTheme } from '../../theme/themeContext';
import UserMenu from './UserMenu';

const Header = ({ toggleSidebar }) => {
  const { isDark, toggleTheme, sidebarColor, setSidebarColor } = useTheme();

  // サイドバーカラーの設定
  const colors = {
    blue: '#2563eb',
    green: '#059669',
    red: '#dc2626',
    purple: '#7c3aed'
  };

  return (
    <header className="h-16 flex items-center justify-between px-4 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
      {/* 左側：ハンバーガーメニュー（モバイルのみ） */}
      <div className="flex items-center">
        <button
          onClick={toggleSidebar}
          className="lg:hidden p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
          aria-label="Toggle Sidebar"
        >
          <HiOutlineMenu className="w-6 h-6 text-gray-600 dark:text-gray-300" />
        </button>
      </div>

      {/* 右側：カラー選択、テーマ切り替え、ユーザーメニュー */}
      <div className="flex items-center space-x-4">
        {/* カラー選択 */}
        <div className="flex items-center space-x-2">
          {Object.entries(colors).map(([color, hex]) => (
            <button
              key={color}
              onClick={() => setSidebarColor(color)}
              className={`w-6 h-6 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white
                ${color === sidebarColor ? 'ring-2 ring-offset-2 ring-offset-gray-800 ring-white' : ''}
              `}
              style={{ backgroundColor: hex }}
            />
          ))}
        </div>

        {/* テーマ切り替え */}
        <button
          onClick={toggleTheme}
          className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
        >
          {isDark ? (
            <HiOutlineSun className="w-6 h-6 text-gray-600 dark:text-gray-300" />
          ) : (
            <HiOutlineMoon className="w-6 h-6 text-gray-600 dark:text-gray-300" />
          )}
        </button>

        {/* ユーザーメニュー */}
        <UserMenu />
      </div>
    </header>
  );
};

export default Header;