import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import routes from './routes';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {routes.map(({ path, element, title }) => (
            <Route 
              key={path}
              path={path}
              element={element}
            />
          ))}
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;