import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data, title }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    height: 300,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'rgb(156, 163, 175)',
          boxWidth: 12,
          padding: 8
        }
      },
      title: {
        display: true,
        text: title,
        color: 'rgb(156, 163, 175)',
        font: {
          size: 14
        },
        padding: { top: 10, bottom: 20 }
      }
    },
    scales: {
      y: {
        ticks: {
          color: 'rgb(156, 163, 175)',
          font: {
            size: 11
          }
        },
        grid: {
          color: 'rgba(156, 163, 175, 0.1)'
        }
      },
      x: {
        ticks: {
          color: 'rgb(156, 163, 175)',
          font: {
            size: 11
          }
        },
        grid: {
          color: 'rgba(156, 163, 175, 0.1)'
        }
      }
    }
  };

  return (
    <div className="h-[250px] lg:h-[400px]">
      <Line options={options} data={data} />
    </div>
  );
};

export default LineChart; 